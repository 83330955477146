import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Element } from '@shared/interfaces/element.interface';
import { Link } from '@shared/ui/Link';

interface CategoryQuestionsProps extends Element {
  question: string;
}

function useComponentState() {
  const { t } = useTranslation();

  return {
    t,
  };
}

export const CategoryQuestions: React.FC<CategoryQuestionsProps> = ({ question, className = '' }) => {
  const { t } = useComponentState();

  return (
    <li className={clsx('mt-6 first:mt-0', className)}>
      <Link to={`/questions-answers/${question}`} className="block text-xs lg:text-base hover:underline">
        {t(question)}
      </Link>
    </li>
  );
};
