export default [
  {
    categoryName: 'questions-category-get-help',
    locality: ['uk'],
    list: [
      {
        question: 'question-category-get-help-1',
        answer: 'answer-category-get-help-1',
        locality: ['uk'],
      },
      {
        question: 'question-category-get-help-2',
        answer: 'answer-category-get-help-2',
        locality: ['uk'],
      },
      {
        question: 'question-category-get-help-3',
        answer: 'answer-category-get-help-3',
        locality: ['uk'],
      },
      {
        question: 'question-category-get-help-4',
        answer: 'answer-category-get-help-4',
        locality: ['uk'],
      },
      {
        question: 'question-category-get-help-5',
        answer: 'answer-category-get-help-5',
        locality: ['uk'],
      },
      {
        question: 'question-category-get-help-6',
        answer: 'answer-category-get-help-6',
        locality: ['uk'],
      },
      {
        question: 'what-to-do-if-i-cant-find-my-locality',
        answer: 'what-to-do-if-i-cant-find-my-locality-answer',
        locality: ['uk'],
      },
      {
        question: 'what-is-validity-period-of-application',
        answer: 'what-is-validity-period-of-application-answer',
        locality: ['uk'],
      },
      {
        question: 'question-category-get-help-7',
        answer: 'answer-category-get-help-7',
        locality: ['uk'],
      },
    ],
  },
  {
    categoryName: 'questions-category-online-help',
    locality: ['uk', 'en', 'pl', 'de', 'fr'],
    list: [
      {
        question: 'question-category-online-help-1',
        answer: 'answer-category-online-help-1',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
      {
        question: 'question-category-online-help-2',
        answer: 'answer-category-online-help-2',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
      {
        question: 'question-category-online-help-3',
        answer: 'answer-category-online-help-3',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
      {
        question: 'question-category-online-help-4',
        answer: 'answer-category-online-help-4',
        locality: ['uk'],
      },
      {
        question: 'question-category-online-help-5',
        answer: 'answer-category-online-help-5',
        locality: ['uk'],
      },
      {
        question: 'question-category-online-help-6',
        answer: 'answer-category-online-help-6',
        locality: ['uk'],
      },
      {
        question: 'question-category-online-help-7',
        answer: 'answer-category-online-help-7',
        locality: ['uk'],
      },
      {
        question: 'question-category-online-help-8',
        answer: 'answer-category-online-help-8',
        locality: ['uk'],
      },
      {
        question: 'question-category-online-help-9',
        answer: 'answer-category-online-help-9',
        locality: ['uk'],
      },
      {
        question: 'question-category-online-help-10',
        answer: 'answer-category-online-help-10',
        locality: ['uk'],
      },
      {
        question: 'question-category-online-help-11',
        answer: 'answer-category-online-help-11',
        locality: ['uk'],
      },
    ],
  },
  {
    categoryName: 'questions-category-silpo',
    locality: ['uk', 'en', 'pl', 'de', 'fr'],
    list: [
      {
        question: 'question-category-silpo-1',
        answer: 'answer-category-silpo-1',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
      {
        question: 'question-category-silpo-2',
        answer: 'answer-category-silpo-2',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
      {
        question: 'question-category-silpo-3',
        answer: 'answer-category-silpo-3',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
      {
        question: 'question-category-silpo-4',
        answer: 'answer-category-silpo-4',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
      {
        question: 'question-category-silpo-5',
        answer: 'answer-category-silpo-5',
        locality: ['uk'],
      },
      {
        question: 'question-category-silpo-6',
        answer: 'answer-category-silpo-6',
        locality: ['uk'],
      },
      {
        question: 'question-category-silpo-7',
        answer: 'answer-category-silpo-7',
        locality: ['uk'],
      },
      {
        question: 'question-category-silpo-8',
        answer: 'answer-category-silpo-8',
        locality: ['uk'],
      },
      {
        question: 'question-category-silpo-9',
        answer: 'answer-category-silpo-9',
        locality: ['uk'],
      },
      {
        question: 'question-category-silpo-10',
        answer: 'answer-category-silpo-10',
        locality: ['uk'],
      },
      {
        question: 'question-category-silpo-11',
        answer: 'answer-category-silpo-11',
        locality: ['uk'],
      },
      {
        question: 'question-category-silpo-12',
        answer: 'answer-category-silpo-12',
        locality: ['uk'],
      },
      {
        question: 'question-category-silpo-13',
        answer: 'answer-category-silpo-13',
        locality: ['uk'],
      },
      {
        question: 'question-category-silpo-14',
        answer: 'answer-category-silpo-14',
        locality: ['uk'],
      },
    ],
  },
  {
    categoryName: 'questions-category-pharmacy',
    locality: ['uk', 'en', 'pl', 'de', 'fr'],
    list: [
      {
        question: 'question-category-pharmacy-1',
        answer: 'answer-category-pharmacy-1',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
      {
        question: 'question-category-pharmacy-2',
        answer: 'answer-category-pharmacy-2',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
      {
        question: 'question-category-pharmacy-3',
        answer: 'answer-category-pharmacy-3',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
      {
        question: 'question-category-pharmacy-4',
        answer: 'answer-category-pharmacy-4',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
      {
        question: 'question-category-pharmacy-5',
        answer: 'answer-category-pharmacy-5',
        locality: ['uk'],
      },
      {
        question: 'question-category-pharmacy-6',
        answer: 'answer-category-pharmacy-6',
        locality: ['uk'],
      },
      {
        question: 'question-category-pharmacy-7',
        answer: 'answer-category-pharmacy-7',
        locality: ['uk'],
      },
      {
        question: 'question-category-pharmacy-8',
        answer: 'answer-category-pharmacy-8',
        locality: ['uk'],
      },
      {
        question: 'question-category-pharmacy-9',
        answer: 'answer-category-pharmacy-9',
        locality: ['uk'],
      },
      {
        question: 'question-category-pharmacy-10',
        answer: 'answer-category-pharmacy-10',
        locality: ['uk'],
      },
      {
        question: 'question-category-pharmacy-11',
        answer: 'answer-category-pharmacy-11',
        locality: ['uk'],
      },
      {
        question: 'question-category-pharmacy-12',
        answer: 'answer-category-pharmacy-12',
        locality: ['uk'],
      },
    ],
  },
  {
    categoryName: 'questions-category-wog',
    locality: ['uk', 'en', 'pl', 'de', 'fr'],
    list: [
      {
        question: 'question-category-wog-1',
        answer: 'answer-category-wog-1',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
      {
        question: 'question-category-wog-2',
        answer: 'answer-category-wog-2',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
      {
        question: 'question-category-wog-3',
        answer: 'answer-category-wog-3',
        locality: ['uk'],
      },
      {
        question: 'question-category-wog-4',
        answer: 'answer-category-wog-4',
        locality: ['uk'],
      },
      {
        question: 'question-category-wog-5',
        answer: 'answer-category-wog-5',
        locality: ['uk'],
      },
      {
        question: 'question-category-wog-6',
        answer: 'answer-category-wog-6',
        locality: ['uk'],
      },
      {
        question: 'question-category-wog-7',
        answer: 'answer-category-wog-7',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
      {
        question: 'question-category-wog-8',
        answer: 'answer-category-wog-8',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
      {
        question: 'question-category-wog-9',
        answer: 'answer-category-wog-9',
        locality: ['uk'],
      },
      {
        question: 'question-category-wog-10',
        answer: 'answer-category-wog-10',
        locality: ['uk'],
      },
      {
        question: 'question-category-wog-11',
        answer: 'answer-category-wog-11',
        locality: ['uk'],
      },
      {
        question: 'question-category-wog-12',
        answer: 'answer-category-wog-12',
        locality: ['uk'],
      },
      {
        question: 'question-category-wog-13',
        answer: 'answer-category-wog-13',
        locality: ['uk'],
      },
      {
        question: 'question-category-wog-14',
        answer: 'answer-category-wog-14',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
      {
        question: 'question-category-wog-15',
        answer: 'answer-category-wog-15',
        locality: ['uk'],
      },
    ],
  },
  {
    categoryName: 'questions-category-atb',
    locality: ['uk', 'en', 'pl', 'de', 'fr'],
    list: [
      {
        question: 'questions-category-atb-1',
        answer:
          'Якщо у вас достатньо коштів на рахунку, ви не перевищили встановлений ліміт оплат в Інтернеті, вам необхідно звернутись на Гарячу Лінію за номером 0800500415.',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
      {
        question: 'questions-category-atb-2',
        answer: 'Ні, кожна заявка оплачується окремо',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
      {
        question: 'questions-category-atb-3',
        answer:
          'Якщо у вас виникають проблеми з використанням Передплати єДопомога в одному із магазинів АТБ-Маркет, вам потрібно звернутись на Гарячу Лінію за телефоном 0800500415, де фахівці зможуть перевірити статус Передплати єДопомога.',
        locality: ['uk'],
      },
      {
        question: 'questions-category-atb-4',
        answer:
          'Передплата єДопомога, передусім, використовується на товари, вказані в заявці. Якщо потрібні вам товари відсутні в магазині, ви можете їх замінити іншими товарами, окрім алкогольних та тютюнових виробів.',
        locality: ['uk'],
      },
      {
        question: 'questions-category-atb-5',
        answer:
          'Ви зможете використати Передплату єДопомога, просканувавши штрих-код роздрукованого сертифікату на касі, або продиктувати 8-ми значний код касиру.',
        locality: ['uk'],
      },
      {
        question: 'questions-category-atb-6',
        answer:
          'Ні, Передплата єДопомога це наче поповнення віртуального рахунку, кошти з якого можна витратити лише на товари з категорій, що зазначені в заявці. Не підлягає, а також не переводиться в грошовий еквівалент.',
        locality: ['uk'],
      },
      {
        question: 'questions-category-atb-7',
        answer:
          'Після того, як людина купила товар, з використанням Передплати єДопомога, на наступний день у вашому особистому кабінеті з’явиться звіт про використання у вигляді чека і ви зможете ознайомитись із ним.',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
      {
        question: 'questions-category-atb-8',
        answer:
          'Наразі використати Передплату єДопомога можна лише в магазинах «АТБ-Маркет». Поки немає можливості використати в інтернет-магазині Передплату єДопомога.',
        locality: ['uk'],
      },
      {
        question: 'questions-category-atb-9',
        answer:
          'Ні, Передплату можна використати лише 1 раз. При цьому сума покупки має дорівнювати або перевищувати номінал Передплати єДопомога. Якщо вартість товару перевищує номінал Передплата єДопомога, її пред’явник доплачує різницю між номіналом Передплати єДопомога та вартістю товару готівковими грошовими коштами.',
        locality: ['uk'],
      },
      {
        question: 'questions-category-atb-10',
        answer: 'Наразі в одному чеку може бути використана лише одна Передплата єДопомога.',
        locality: ['uk'],
      },
      {
        question: 'questions-category-atb-11',
        answer:
          'Термін дії Передплати єДопомога зазначений у СМС повідомлені з кодом. Також ви можете знайти цю інформацію в особистому кабінеті на платформі єДопомога.',
        locality: ['uk'],
      },
      {
        question: 'questions-category-atb-12',
        answer: 'Так, безперечно. Усі акції в магазинах діють для Передплати.',
        locality: ['uk'],
      },
    ],
  },
  {
    categoryName: 'questions-category-hand-to-hand',
    locality: ['uk'],
    list: [
      {
        question: 'question-category-hand-to-hand-1',
        answer: 'answer-category-hand-to-hand-1',
        locality: ['uk'],
      },
      {
        question: 'question-category-hand-to-hand-2',
        answer: 'answer-category-hand-to-hand-2',
        locality: ['uk'],
      },
      {
        question: 'question-category-hand-to-hand-3',
        answer: 'answer-category-hand-to-hand-3',
        locality: ['uk'],
      },
      {
        question: 'question-category-hand-to-hand-4',
        answer: 'answer-category-hand-to-hand-4',
        locality: ['uk'],
      },
      {
        question: 'question-category-hand-to-hand-5',
        answer: 'answer-category-hand-to-hand-5',
        locality: ['uk'],
      },
      {
        question: 'question-category-hand-to-hand-6',
        answer: 'answer-category-hand-to-hand-6',
        locality: ['uk'],
      },
      {
        question: 'question-category-hand-to-hand-7',
        answer: 'answer-category-hand-to-hand-7',
        locality: ['uk'],
      },
      {
        question: 'question-category-hand-to-hand-8',
        answer: 'answer-category-hand-to-hand-8',
        locality: ['uk'],
      },
      {
        question: 'question-category-hand-to-hand-9',
        answer: 'answer-category-hand-to-hand-9',
        locality: ['uk'],
      },
    ],
  },
  {
    categoryName: 'legion-instruction-category',
    locality: ['uk', 'en', 'pl', 'de', 'fr'],

    list: [
      {
        question: 'legion-instruction-question-1',
        answer: 'legion-instruction-question-1',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
      {
        question: 'legion-instruction-question-2',
        answer: 'legion-instruction-question-2',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
      {
        question: 'legion-instruction-question-3',
        answer: 'legion-instruction-question-3',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
      {
        question: 'legion-instruction-question-5',
        answer: 'legion-instruction-question-5',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
      {
        question: 'legion-instruction-question-4',
        answer: 'legion-instruction-question-4',
        locality: ['uk', 'en', 'pl', 'de', 'fr'],
      },
    ],
  },
  {
    categoryName: 'questions-category-anc',
    locality: ['uk'],
    list: [
      {
        question: 'questions-category-anc-1',
        answer: 'answer-category-anc-1',
        locality: ['uk'],
      },
      {
        question: 'questions-category-anc-2',
        answer: 'answer-category-anc-2',
        locality: ['uk'],
      },
      {
        question: 'questions-category-anc-3',
        answer: 'answer-category-anc-3',
        locality: ['uk'],
      },
      {
        question: 'questions-category-anc-4',
        answer: 'answer-category-anc-4',
        locality: ['uk'],
      },
      {
        question: 'questions-category-anc-5',
        answer: 'answer-category-anc-5',
        locality: ['uk'],
      },
      {
        question: 'questions-category-anc-6',
        answer: 'answer-category-anc-6',
        locality: ['uk'],
      },
      {
        question: 'questions-category-anc-7',
        answer: 'answer-category-anc-7',
        locality: ['uk'],
      },
      {
        question: 'questions-category-anc-8',
        answer: 'answer-category-anc-8',
        locality: ['uk'],
      },
      {
        question: 'questions-category-anc-9',
        answer: 'answer-category-anc-9',
        locality: ['uk'],
      },
      {
        question: 'questions-category-anc-10',
        answer: 'answer-category-anc-10',
        locality: ['uk'],
      },
      {
        question: 'questions-category-anc-11',
        answer: 'answer-category-anc-11',
        locality: ['uk'],
      },
      {
        question: 'questions-category-anc-12',
        answer: 'answer-category-anc-12',
        locality: ['uk'],
      },
    ],
  },
  // {
  //   categoryName: 'questions-category-big-basket',
  //   locality: ['uk'],
  //   list: [
  //     {
  //       question: 'questions-category-big-basket-1',
  //       answer: 'answer-category-big-basket-1',
  //       locality: ['uk'],
  //     },
  //     {
  //       question: 'questions-category-big-basket-2',
  //       answer: 'answer-category-big-basket-2',
  //       locality: ['uk'],
  //     },
  //     {
  //       question: 'questions-category-big-basket-3',
  //       answer: 'answer-category-big-basket-3',
  //       locality: ['uk'],
  //     },
  //     {
  //       question: 'questions-category-big-basket-4',
  //       answer: 'answer-category-big-basket-4',
  //       locality: ['uk'],
  //     },
  //     {
  //       question: 'questions-category-big-basket-5',
  //       answer: 'answer-category-big-basket-5',
  //       locality: ['uk'],
  //     },
  //     {
  //       question: 'questions-category-big-basket-6',
  //       answer: 'answer-category-big-basket-6',
  //       locality: ['uk'],
  //     },
  //     {
  //       question: 'questions-category-big-basket-7',
  //       answer: 'answer-category-big-basket-7',
  //       locality: ['uk'],
  //     },
  //   ],
  // },
];
