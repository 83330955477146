import { ArrowDownIcon } from '@shared/icons/arrow-down';
import clsx from 'clsx';
import React from 'react';

interface QuestionsAccordionHeaderProps {
  title: string;
  isCollapsed: boolean;
  className?: string;
  onClick: () => void;
}

export const QuestionsAccordionHeader: React.FC<QuestionsAccordionHeaderProps> = ({
  title,
  isCollapsed,
  className = '',
  onClick,
}) => {
  return (
    <div
      className={clsx(
        'flex justify-between p-4 bg-solitude transition-colors rounded-xxs lg:block lg:p-0 lg:rounded-none lg:bg-transparent lg:cursor-default',
        className,
      )}
      onClick={onClick}
      role="button"
    >
      <h2 className="mr-4 lg:text-lg lg:mr-0 xl:text-xl text-black-50">{title}</h2>
      <ArrowDownIcon
        className={clsx('w-3 h-2 mt-2 shrink-0 transition-transform lg:hidden', !isCollapsed && 'rotate-180')}
      />
    </div>
  );
};
