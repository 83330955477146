import clsx from 'clsx';
import React from 'react';
import { CategoryQuestions } from './CategoryQuestions';

type TVariant = 'general' | 'specific';
interface QuestionsAccordionContentProps {
  variant: TVariant;
  isCollapsed: boolean;
  questions: string[];
}

const styleConfig = {
  list: {
    general: 'xl:flex xl:flex-wrap xl:gap-y-8 xl:justify-between',
    specific: 'lg:mt-6',
  },
  listItem: {
    general: 'xl:basis-[356px] xl:mt-0',
    specific: '',
  },
};

function useComponentState({ variant, ...restProps }: QuestionsAccordionContentProps) {
  const listCn = styleConfig.list[variant];
  const listItemCn = styleConfig.listItem[variant];

  return {
    ...restProps,
    listCn,
    listItemCn,
  };
}

export const QuestionsAccordionContent: React.FC<QuestionsAccordionContentProps> = (props) => {
  const { isCollapsed, questions, listCn, listItemCn } = useComponentState(props);

  return (
    <ul
      className={clsx(
        'px-4 py-6 lg:px-8 lg:py-7 lg:border-2 lg:border-hawkes-blue lg:rounded-lg lg:bg-white',
        isCollapsed ? 'hidden' : '',
        listCn,
      )}
    >
      {questions.map((question) => (
        <CategoryQuestions key={question} question={question} className={listItemCn} />
      ))}
    </ul>
  );
};
