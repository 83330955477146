import { useLanguage } from '@hooks/useLanguage';
import categoryQaData from '@values/questions-answers';
import React from 'react';
import { QAGeneralCategory } from './QAGeneralCategory';

function useComponentState() {
  const language = useLanguage();
  const categoryQAfilteredByLocality = categoryQaData.filter((v) => v.locality.includes(language));

  return {
    categoryQa: categoryQAfilteredByLocality,
  };
}

export const QAGeneralMain = () => {
  const { categoryQa } = useComponentState();

  return (
    <div className="mt-6 lg:mt-20">
      {categoryQa.map((v, i) => (
        <QAGeneralCategory
          key={v.categoryName}
          categoryName={v.categoryName}
          defaultIsCollapsed={i === 0 ? false : true}
        />
      ))}
    </div>
  );
};
