import useMinWidthMediaQuery from '@hooks/useMinWidthMediaQuery';
import { useToggle } from '@hooks/useToggle';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionsAccordionContent } from '../QuestionsAccordionContent';
import { QuestionsAccordionHeader } from '../QuestionsAccordionHeader';
import categoryQaData from '@values/questions-answers';
import { useLanguage } from '@hooks/useLanguage';
interface QAGeneralCategoryProps {
  categoryName: string;
  defaultIsCollapsed: boolean;
}

function useComponentState(props: QAGeneralCategoryProps) {
  const { defaultIsCollapsed, categoryName } = props;
  const { t } = useTranslation();
  const language = useLanguage();
  const [isCollapsed, toggleCollapsed] = useToggle({ defaultValue: defaultIsCollapsed });
  const isLargeScreen = useMinWidthMediaQuery('lg');
  const questions = useMemo(
    () => getQuestions(categoryQaData, categoryName, language),
    [categoryQaData, categoryName, language],
  );

  const handleToggleAccordion = () => {
    if (isLargeScreen) return;
    toggleCollapsed();
  };

  useEffect(() => {
    if (isLargeScreen && isCollapsed) {
      toggleCollapsed();
    }
  }, [isLargeScreen, isCollapsed]);

  return {
    ...props,
    t,
    questions,
    isCollapsed,
    handleToggleAccordion,
  };
}

export const QAGeneralCategory: React.FC<QAGeneralCategoryProps> = (props) => {
  const { isCollapsed, questions, categoryName, t, handleToggleAccordion } = useComponentState(props);

  return (
    <div className="mt-6 first:mt-0 lg:flex">
      <QuestionsAccordionHeader
        isCollapsed={isCollapsed}
        title={t(categoryName)}
        className="lg:basis-80 xl:basis-96 lg:mt-7 lg:shrink-0 lg:mr-8 xl:mt-6"
        onClick={handleToggleAccordion}
      />
      <QuestionsAccordionContent isCollapsed={isCollapsed} variant="general" questions={questions} />
    </div>
  );
};

function getQuestions(qaCategories: any, categoryName: string, language: string) {
  const qaList = qaCategories.find((v) => v.categoryName === categoryName)!.list;
  const qaListFilteredByLocality = qaList.filter((v) => v.locality.includes(language));
  return qaListFilteredByLocality.map((v) => v.question);
}
