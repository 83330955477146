import * as React from 'react';

import { QAGeneralMain } from '@widgets/questions-answers/general/QAGeneralMain';
import { useTranslation } from 'react-i18next';

const QAGeneralPage = () => {
  const { t } = useTranslation();

  return (
    <div className="pt-6 pb-12 lg:pt-12 xl:pb-52">
      <div className="container_xl">
        <h1 className="text-2xl lg:text-4xl">{t('questions-and-answers')}</h1>
        <QAGeneralMain />
      </div>
    </div>
  );
};

export default QAGeneralPage;
